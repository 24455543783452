<template>
  <div>
    <div class="contain1">
      <div class="flex-center title">
        营销活动订单
        <helpIcon
          style="margin-left:6px;"
          prompt="统计网校课程累计参与营销活动的订单数量，包括已过期和已作废的订单；饼图按累计数据统计；柱状图按本月和上月统计"
        ></helpIcon>
      </div>
      <div class="flex-center course-detail">
        <div class="flex-center item">
          全部
          <i class="number">{{ saleOrderNum }}</i
          >笔
        </div>
        <div class="flex-center item">
          今日
          <i class="number">{{ todaySaleNum }}</i
          >笔
        </div>
      </div>
      <div class="_center">
        <div class="vt">
          <div class="pieChart">
            <pieChart :pieData="list"></pieChart>
          </div>
        </div>
        <!-- <div class="vt space-bd"></div> -->
        <div class="vt treeChart">
          <treeChart :lists="list2" class="tree-caht"></treeChart>
        </div>
      </div>
    </div>
    <!--学员增长趋势分析-->
    <div class="mt20 studentGrowthChart">
      <div class="flex-center header">
        <div class="title">营销活动增长趋势（元）</div>
        <selectTimeInterval
          class="selectTim"
          v-model="post.area_type"
          @input="input"
          :option="[
            { name: '最近7天', id: 1 },
            { name: '最近30天', id: 2 }
          ]"
        ></selectTimeInterval>
        <el-date-picker
          :value="post.time"
          @input="changeTime"
          type="daterange"
          align="right"
          unlink-panels
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :pickerOptions="pickerOptions"
        ></el-date-picker>
      </div>
      <div class="line-chart">
        <lineChart :options="saleIncrementData"></lineChart>
      </div>
    </div>
  </div>
</template>
<script>
import pieChart from '@/components/dataAnalysis/pieChart'
import treeChart from '@/components/dataAnalysis/tree'
import lineChart from '@/components/dataAnalysis/line'
import selectTimeInterval from './selectTimeInterval'
import helpIcon from '@/components/helpIcon'

export default {
  name: 'marketingAnalysis',

  components: {
    pieChart,
    treeChart,
    lineChart,
    helpIcon,
    selectTimeInterval
  },

  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 86400000
        }
      },

      // 网校学员 付费 访问 已拉黑
      list: [],

      // 网校学员 本月 上月 付费 访问
      list2: [],

      // 学员增长趋势分析
      post: {
        startTime: '',
        endTime: '',
        time: '',
        area_type: 1
      },

      todaySaleNum: '--',
      saleOrderNum: '--',
      groupOrderNum: '--',
      fissionOrderNum: '--',
      couponOrderNum: '--',
      redeemOrderNum: '--',
      // todaySaleNum字段：今日营销订单数
      // saleOrderNum字段：全部营销订单数
      // groupOrderNum字段：拼团订单数
      // fissionOrderNum字段：裂变海报订单数
      // couponOrderNum字段：优惠券订单数
      // redeemOrderNum字段：兑换码订单数

      theMonth: {
        // group_order_num:本月拼团订单数
        // redeem_order_num:本月裂变海报订单数
        // coupon_order_num:本月优惠券订单数
        // fission_order_num:本月兑换码订单数
      },
      lastMonth: {
        // group_order_num:上月拼团订单数
        // redeem_order_num:上月裂变海报订单数
        // coupon_order_num:上月优惠券订单数
        // fission_order_num:上月兑换码订单数
      },

      saleIncrementData: {}
    }
  },

  watch: {
    post: {
      handler() {
        // 获取营销增长趋势数据
        this.getSaleIncrementData()
      },
      deep: true,
      immediate: true
    }
  },

  created() {
    this.getData()
  },

  methods: {
    //监听 最近七天的点击事件
    input() {
      this.post.startTime = ''
      this.post.endTime = ''
      this.post.time = ''
    },

    // 基础数据API
    getData() {
      this.$http({
        url: '/statistics/saleBaseData',
        callback: ({ data: { todaySaleNum, saleOrderNum, groupOrderNum, fissionOrderNum, couponOrderNum, redeemOrderNum, theMonth, lastMonth } }) => {
          this.todaySaleNum = todaySaleNum
          this.saleOrderNum = saleOrderNum
          this.groupOrderNum = groupOrderNum
          this.fissionOrderNum = fissionOrderNum
          this.couponOrderNum = couponOrderNum
          this.redeemOrderNum = redeemOrderNum

          this.theMonth = theMonth
          this.lastMonth = lastMonth

          // 网校学员
          this.list = [
            {
              value: groupOrderNum,
              name: '拼团',
              itemStyle: { color: '#0093F8' }
            },
            {
              value: fissionOrderNum,
              name: '裂变海报',
              itemStyle: { color: '#FED74C' }
            },
            {
              value: couponOrderNum,
              name: '优惠券',
              itemStyle: { color: '#66B358' }
            },
            {
              value: redeemOrderNum,
              name: '兑换码',
              itemStyle: { color: '#FA6400' }
            }
          ]

          // console.log(this.list)

          // 网校学员 本月 上月 付费 访问
          this.list2 = [
            ['product', '本月', '上月'],
            ['拼团', theMonth.group_order_num, lastMonth.group_order_num],
            ['裂变海报', theMonth.fission_order_num, lastMonth.fission_order_num],
            ['优惠券', theMonth.coupon_order_num, lastMonth.coupon_order_num],
            ['兑换码', theMonth.redeem_order_num, lastMonth.redeem_order_num]
          ]
        }
      })
    },

    // 营销活动增长趋势分析
    getSaleIncrementData() {
      this.$http({
        url: '/statistics/saleIncrement',
        data: this.post,
        callback: ({ data: { saleAmountIncrement } }) => {
          this.saleIncrementData = {
            legend: {
              left: 0,
              data: ['优惠券', '拼团', '裂变海报']
            },
            xAxis: {
              data: saleAmountIncrement.map(item => item[0])
            },
            series: [
              {
                name: '优惠券',
                type: 'line',
                data: saleAmountIncrement.map(item => item[1]),
                lineStyle: {
                  color: '#00D966'
                },
                itemStyle: {
                  color: '#00D966',
                  borderWidth: 4
                },
                smooth: true
              },
              {
                name: '拼团',
                type: 'line',
                data: saleAmountIncrement.map(item => item[2]),
                lineStyle: {
                  color: '#0093F8'
                },
                itemStyle: {
                  color: '#0093F8',
                  borderWidth: 4
                },
                smooth: true
              },
              {
                name: '裂变海报',
                type: 'line',
                data: saleAmountIncrement.map(item => item[3]),
                lineStyle: {
                  color: '#F8B832'
                },
                itemStyle: {
                  color: '#F8B832',
                  borderWidth: 4
                },
                smooth: true
              }
            ]
          }
        }
      })
    },

    changeTime(value) {
      this.post = Object.assign({}, this.post, {
        startTime: value ? value[0] / 1000 : '',
        endTime: value ? value[1] / 1000 : '',
        time: value || '',
        area_type: value ? 3 : 1
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.contain1 {
  padding: 20px;
  margin-top: 20px;
  background: #fff;
  white-space: nowrap;
}
.title {
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  color: rgba(51, 51, 51, 1);
}
.course-detail {
  margin: 10px 0 30px;
  .item {
    font-size: 12px;
    margin-right: 40px;
    position: relative;
    align-items: baseline;
    color: rgba(51, 51, 51, 1);
    > i {
      margin: 0 5px;
      font-size: 24px;
      font-weight: bold;
      line-height: 31px;
      position: relative;
      top: 2px;
      color: rgba(51, 51, 51, 1);
    }
    .helpIcon {
      position: absolute;
      top: -4px;
      right: -11px;
    }
  }
}
.pieChart,
.treeChart {
  font-size: 0;
  position: relative;
}
.pieChart {
  min-width: 370px;
  height: 206px;
  margin-top: 30px;
}
.space-bd {
  width: 1px;
  height: 266px;
  margin: 0 120px 0 60px;
  background: rgba(216, 216, 216, 1);
}
.treeChart {
  min-width: 300px;
  width: 500px;
  height: 266px;
  top: -35px;
  margin-left: 10%;
}
.select-time {
  width: 316px;
  height: 30px;
}
.proportionChart,
.studentGrowthChart {
  padding: 20px;
  background: #fff;
  .title {
    flex: 1;
    font-size: 14px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 19px;
  }
  .pie-contain {
    height: 228px;
    position: relative;
  }
  .char-menu {
    width: 316px;
    height: 30px;
    margin: 20px auto 0;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(229, 229, 229, 1);
  }
}
.studentGrowthChart {
  padding-bottom: 30px;
  .header {
    margin-bottom: 12px;
    .selectTim {
      width: 158px;
      margin-right: 20px;
    }
    ::v-deep .el-input,
    ::v-deep .el-range-input {
      font-size: 12px;
      line-height: 30px;
    }
    ::v-deep .el-range-separator,
    ::v-deep .el-date-editor .el-range__icon,
    ::v-deep .el-date-editor .el-range__close-icon {
      line-height: 25px;
    }
    ::v-deep .el-form-item__content {
      height: 30px;
    }
    ::v-deep .el-input__inner,
    ::v-deep .el-input {
      width: 120px;
    }
    ::v-deep .el-date-editor {
      width: 220px;
      height: 30px;
    }
    ::v-deep .el-form-item {
      margin: 0;
    }
  }
}
.line-chart {
  height: 305px;
  position: relative;
}
</style>
